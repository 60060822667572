





















































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class MyOrder extends Mixins(Methods) {
    checkIndex: number = 0
    list: any = [
        {
            name: '全部订单',
            num: '0'
        },
        {
            name: '待接单',
            num: '0'
        },
        {
            name: '进行中',
            num: '0'
        },
        {
            name: '已结束',
            num: '0'
        },
    ]

    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }
    checkList(index: number): void {
        this.checkIndex = index
    }
}
